import React, { useEffect, useState } from 'react';

import { Box, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { FillLayerSpecification } from 'mapbox-gl';
import { isMobile } from 'react-device-detect';
import { Layer, Popup, Source, useMap } from 'react-map-gl';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../../context/Translations';
import {
  usePreSelectedProperty,
  useProperties,
  useSelectedProperty,
} from '../../../state';
import { getPolygonBounds } from '../../../utils';
import { PropertyMarkers } from './PropertyMarkers';

const SOURCE_ID = 'properties-data';

const layerStyleProperties: FillLayerSpecification = {
  id: 'properties',
  source: SOURCE_ID,
  type: 'fill',
  paint: {
    'fill-color': '#5865F6',
    'fill-opacity': 0.3,
  },
};

function PropertiesLayer() {
  const { mainMap } = useMap();
  const [properties] = useProperties();
  const [selectedProperty] = useSelectedProperty();
  const [, preSelectProperty] = usePreSelectedProperty();

  const bg = useColorModeValue('white', 'gray.900');

  const [hoverInfo, setHoverInfo] = useState<any>(null);
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const { translate } = useTranslation();

  const geojsonProperties: any = React.useMemo(
    () => ({
      type: 'FeatureCollection',
      features: properties.map((property) => ({
        type: 'Feature',
        geometry: property.geo,
        properties: { ...property },
      })),
    }),
    [properties],
  );

  // Add event listeners
  useEffect(() => {
    const handleMapOnClick = (e: any) => {
      if (e.propertyTargetId) {
        const property = properties.find(({ id }) => id === e.propertyTargetId);

        if (property) {
          onClick({
            property,
            lat: property.centroid.coordinates[1],
            lng: property.centroid.coordinates[0],
          });
        }
      }
    };
    mainMap?.on('click', handleMapOnClick);

    return () => {
      mainMap?.off('click', handleMapOnClick);
    };
  }, []);

  const onMouseLeave = React.useCallback(() => {
    setHoverInfo(null);
  }, []);

  const onMouseOver = React.useCallback(
    (event: any) => {
      const { property, x, y } = event;
      if (!isMobile) {
        setHoverInfo(
          property !== selectedProperty?.id
            ? { feature: property, x, y }
            : null,
        );
      }
    },
    [selectedProperty],
  );

  const onClick = React.useCallback(
    (event: any) => {
      const { property, lat, lng } = event;

      // Zoom to bounds
      const bounds = getPolygonBounds(property.geo);
      mainMap?.fitBounds(bounds, {
        maxZoom: Math.max(mainMap?.getZoom(), 12),
        essential: true,
      });

      setPopupInfo(
        property !== selectedProperty?.id
          ? { feature: property, lat, lng }
          : null,
      );

      preSelectProperty(property);
    },
    [selectedProperty],
  );

  const filter = React.useMemo(
    () => ['==', 'id', popupInfo ? popupInfo?.feature?.id : false],
    [popupInfo],
  );

  const popupOnClose = React.useCallback(() => {
    preSelectProperty(null);
    setPopupInfo(null);
  }, []);

  if (!mainMap) {
    return null;
  }

  return (
    <>
      <Source id={SOURCE_ID} type="geojson" data={geojsonProperties}>
        <Layer {...layerStyleProperties} filter={filter} />
      </Source>
      <PropertyMarkers
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      />

      {hoverInfo && (
        <Box
          position="fixed"
          m="2"
          p="2"
          background="black"
          color="white"
          maxWidth="72"
          fontSize="14"
          zIndex="9"
          pointerEvents="none"
          left={hoverInfo.x}
          top={hoverInfo.y}
        >
          <Text>{hoverInfo.feature.name}</Text>
          <Box>
            <Text>
              {hoverInfo.feature.region}, {hoverInfo.feature.city}
            </Text>
          </Box>
        </Box>
      )}

      {popupInfo && (
        <Popup
          anchor="top"
          longitude={Number(popupInfo.lng)}
          latitude={Number(popupInfo.lat)}
          closeOnClick={false}
          closeButton
          style={{ maxWidth: '50rem' }}
          onClose={popupOnClose}
        >
          <VStack
            boxShadow="lg"
            bg={bg}
            p="4"
            spacing="2"
            w="100%"
            alignItems="flex-start"
            fontSize="15"
          >
            <Box mb="2" alignItems="baseline">
              <Box mb="2">
                <Text fontSize="18" as="b">
                  {popupInfo.feature.name}
                </Text>
              </Box>
              <Text>
                {popupInfo.feature.region}, {popupInfo.feature.city}{' '}
              </Text>
              <Text marginTop="0">KPID {popupInfo.feature.id}</Text>
            </Box>
            <Link
              onClick={() => setPopupInfo(null)}
              to={`/${popupInfo.feature.id}`}
              className="green-button"
            >
              {translate('show-property')}
            </Link>
          </VStack>
        </Popup>
      )}
    </>
  );
}

export default PropertiesLayer;
