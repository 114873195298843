import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import translations from './translations.json';

interface Translations {
  [key: string]: string;
}

const TranslationContext = React.createContext({
  translate: (text: string): string => text,
  currentLanguage: '',
});

const FALLBACK_LANGUAGE = 'fi';

function TranslationProvider({ children }: { children: React.ReactNode }) {
  const [searchParams] = useSearchParams();
  const [language, setLanguage] = useState<string>('');
  const translate = useCallback(
    (text: string) => {
      const lang = language || FALLBACK_LANGUAGE;
      const languageTranslations: Translations | undefined =
        translations[lang as keyof typeof translations];
      return languageTranslations[text] || text;
    },
    [language],
  );

  const contextValue = React.useMemo(
    () => ({
      translate,
      currentLanguage: language,
    }),
    [language, translate],
  );

  useEffect(() => {
    const lang = searchParams.get('lang');
    if (
      lang &&
      translations[lang.toLowerCase().trim() as keyof typeof translations]
    ) {
      setLanguage(lang.toLowerCase().trim());
    }
  }, [setLanguage, searchParams]);

  return (
    <TranslationContext.Provider value={contextValue}>
      {children}
    </TranslationContext.Provider>
  );
}

const useTranslation = () => useContext(TranslationContext);

export { TranslationProvider, TranslationContext, useTranslation };
export default TranslationContext;
