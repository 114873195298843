import React, { useMemo, memo } from 'react';
import { Marker } from 'react-map-gl';
import {
  useProperties,
  useSelectedProperty,
  usePreSelectedProperty,
} from '../../../state';
import { PinIcon } from '../../Icon';

type Props = {
  onClick: (data: any) => void;
  onMouseOver: (e: any) => void;
  onMouseLeave: () => void;
};

const PropertyMarker = memo(
  ({
    longitude,
    latitude,
    isSelected,
    isNotSelected,
    onClick,
    onMouseOver,
    onMouseLeave,
  }: {
    longitude: number;
    latitude: number;
    isSelected: boolean;
    isNotSelected: boolean;
  } & Props) => {
    return (
      <Marker
        longitude={longitude}
        latitude={latitude}
        anchor="bottom"
        onClick={onClick}
        scale={2}
      >
        <button
          type="button"
          aria-label="Open property"
          className={`property-map-marker ${
            isSelected ? 'property-map-marker--selected' : ' '
          }
          ${isNotSelected ? 'property-map-marker--notselected' : ' '}
          `}
          tabIndex={-1}
          onMouseOver={onMouseOver}
          onFocus={onMouseOver}
          onMouseLeave={onMouseLeave}
        >
          <PinIcon color="#078571" />
        </button>
      </Marker>
    );
  },
);

PropertyMarker.displayName = 'PropertyMarker';

export const PropertyMarkers = memo(
  ({ onClick, onMouseOver, onMouseLeave }: Props) => {
    const [properties] = useProperties();
    const [selectedProperty] = useSelectedProperty();
    const [preSelectedProperty] = usePreSelectedProperty();

    const filteredList = useMemo(
      () => properties.filter((p) => p.id !== selectedProperty?.id),
      [properties.length, selectedProperty],
    );

    return (
      <>
        {filteredList.map((property) => (
          <PropertyMarker
            key={`marker-${property.id}`}
            longitude={property.centroid.coordinates[0]}
            latitude={property.centroid.coordinates[1]}
            isSelected={preSelectedProperty?.id === property.id}
            isNotSelected={
              !!(preSelectedProperty && preSelectedProperty?.id !== property.id)
            }
            onClick={() =>
              onClick({
                property,
                lat: property.centroid.coordinates[1],
                lng: property.centroid.coordinates[0],
              })
            }
            onMouseOver={(e) => {
              onMouseOver({ property, x: e.clientX, y: e.clientY });
            }}
            onMouseLeave={onMouseLeave}
          />
        ))}
      </>
    );
  },
);

PropertyMarkers.displayName = 'PropertyMarkers';
