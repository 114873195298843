import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import { User } from '../types';
import { AuthState, ContextProps } from './types';
import { isOkRequest, removeTokenFromUrl } from './utils';

const initialState: AuthState = {
  isLoggedIn: false,
  isLoading: true,
  isSignout: false,
  userToken: null,
  user: null,
};

export const AuthContext = createContext<ContextProps>({
  signIn: async () => ({ success: false, message: 'Not implemented' }),
  signOut: () => null,
  getUser: async () => ({ success: false, message: 'Not implemented' }),
  authState: initialState,
  user: null,
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [authState, dispatch] = useReducer(
    (
      prevState: AuthState,
      action: { type: string; token?: string | null; user?: User | null },
    ): AuthState => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token || null,
            isLoading: false,
            user: action.user || prevState.user,
          };
        case 'SIGN_IN':
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token || prevState.userToken,
            user: action.user || prevState.user,
            isLoggedIn: true,
          };
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
            user: null,
            isLoggedIn: false,
          };
        default:
          return { ...prevState };
      }
    },
    initialState,
  );

  useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      let userToken: string | null = null;

      try {
        userToken = localStorage.getItem('properties.hoas.fi.userToken');
      } catch (e) {
        console.error(e);
      }

      const urlParams = new URLSearchParams(window.location.search);
      const tokenSearchParam = urlParams.get('token');

      // Check if token is given in URL
      if (!userToken && tokenSearchParam) {
        userToken = tokenSearchParam;
      } else if (
        userToken &&
        tokenSearchParam &&
        userToken !== tokenSearchParam
      ) {
        // If token is in URL and its different than in localstorage
        userToken = tokenSearchParam;
      }

      dispatch({ type: 'RESTORE_TOKEN', token: userToken });
    };

    bootstrapAsync();
  }, []);

  const authContext = useMemo(
    () => ({
      signIn: async ({
        username,
        password,
      }: {
        username: string;
        password: string;
      }) => {
        return fetch(`${process.env.REACT_APP_API_URL}/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username,
            password,
          }),
        })
          .then((response) => response.json())
          .then(async (data) => {
            if (data.success && data.data.token) {
              await localStorage.setItem(
                'properties.hoas.fi.userToken',
                data.data.token,
              );
              dispatch({ type: 'SIGN_IN', token: data.data.token });

              return {
                success: true,
                message: 'log-in-success',
              };
            }
            return {
              success: false,
              message: `API-${data.error || 'unknown-error-alert'}`,
            };
          })
          .catch((e) => {
            return {
              success: false,
              message: e.message,
            };
          });
      },
      signOut: async () => {
        await localStorage.removeItem('properties.hoas.fi.userToken');
        dispatch({ type: 'SIGN_OUT', token: null, user: null });
      },
      getUser: async (token: string) => {
        return fetch(`${process.env.REACT_APP_API_URL}/user`, {
          method: 'GET',
          headers: { authorization: `Bearer ${token || authState.userToken}` },
        })
          .then(isOkRequest)
          .then(async (data) => {
            // Remove token from URL
            removeTokenFromUrl();
            if (data.success) {
              const currentToken = await localStorage.getItem(
                'properties.hoas.fi.userToken',
              );

              // Check if localstorage token is same as token from API
              if (authState.userToken !== currentToken) {
                localStorage.setItem(
                  'properties.hoas.fi.userToken',
                  authState.userToken || '',
                );
              }

              dispatch({ type: 'SIGN_IN', user: data.data });
              return {
                success: true,
                message: '',
              };
            }

            throw new Error(data.message);
          })
          .catch((e) => {
            authContext.signOut();
            console.error(e);
            return {
              success: false,
              message: `API-${e.message || 'unknown-error-alert'}`,
            };
          });
      },
      authState,
      user: authState.user,
    }),
    [authState],
  );

  // Fetch user if userToken set and user null
  useEffect(() => {
    if (authState.user === null && authState.userToken !== null) {
      authContext.getUser(authState.userToken);
    }
  }, [authContext, authState]);

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
}

export default AuthProvider;

export function useAuth() {
  return React.useContext(AuthContext);
}
