import { Box, HStack, Text, useColorModeValue } from '@chakra-ui/react';

import React from 'react';
import { useSelectedProperty } from '../../state';

import { useTranslation } from '../../context/Translations';

import { useAuth } from '../../context/Auth';

function MenuHeaderContent() {
  const [selectedProperty] = useSelectedProperty();
  const { translate } = useTranslation();
  const { user } = useAuth();

  if (!user?.email) {
    return null;
  }

  if (selectedProperty) {
    return (
      <Box>
        <HStack>
          <Text as="b">{translate('menu-title-property')}</Text>

          <Text fontWeight="400" ml="2">
            KPID {selectedProperty.id}
          </Text>
        </HStack>
      </Box>
    );
  }

  return <>{translate('menu-title-home')}</>;
}

export function MenuHeader() {
  const bg = useColorModeValue('#F6F7F7', 'customGray.800');

  return (
    <Box
      alignItems="center"
      pl="5.5rem"
      display="flex"
      width="100%"
      borderTop="1px solid transparent"
      borderBottom="1px solid #e0e0e0"
      bg={bg}
      h="4.1rem"
      lineHeight="1"
    >
      <MenuHeaderContent />
    </Box>
  );
}
