import { ChakraProvider } from '@chakra-ui/react';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'normalize.css';
import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import Loader from './components/Loaders';
import Map from './components/Map';
import Menu from './components/Menu';
import ProfileBar from './components/ProfileBar';
import { useAppLoading, useFetchProperties, useProperties } from './state';
import { AuthProvider, useAuth } from './context/Auth';
import { TranslationProvider } from './context/Translations';
import './Main.scss';
import LoginPage from './pages/Login';
import theme from './theme';

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.authState.userToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // check is logged in
  if (!auth.authState.isLoggedIn) {
    return <Loader />;
  }

  return children;
}

function LogoutPage() {
  const auth = useAuth();
  auth.signOut();
  return <Navigate to="/login" state={{ isLogout: true }} />;
}
function Layout() {
  const auth = useAuth();

  if (auth.authState.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/* <LanguageSelect /> */}
      <ProfileBar />
      <Outlet />
    </>
  );
}

function ProtectedPageContent() {
  const { pathname } = useLocation();
  const [properties] = useProperties();

  if (pathname === '/' && properties.length === 1) {
    return <Navigate to={`${properties[0].id}`} />;
  }

  return (
    <>
      <Menu />
      <Map />
      <Outlet />
    </>
  );
}

function ContentLoader() {
  const auth = useAuth();

  const [isLoading] = useAppLoading();
  const fetchProperties = useFetchProperties();

  useEffect(() => {
    if (auth.authState.userToken && auth.authState.isLoggedIn) {
      fetchProperties();
    }
  }, [auth.authState.userToken, auth.authState.isLoggedIn]);

  return isLoading ? <Loader /> : <ProtectedPageContent />;
}

function ProtectedPage() {
  return (
    <RequireAuth>
      <ContentLoader />
    </RequireAuth>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <TranslationProvider>
          <ChakraProvider theme={theme}>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route path="/:propertySlug" element={<ProtectedPage />} />
                <Route
                  path="/:propertySlug/:buildingSlug"
                  element={<ProtectedPage />}
                />
                <Route
                  path="/:propertySlug/:buildingSlug/:spaceSlug"
                  element={<ProtectedPage />}
                />
                <Route path="/" element={<ProtectedPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/logout" element={<LogoutPage />} />
              </Route>
            </Routes>
          </ChakraProvider>
        </TranslationProvider>
      </Router>
      <Toaster
        position="bottom-center"
        gutter={16}
        toastOptions={{
          className: 'toast',
        }}
      />
    </AuthProvider>
  );
}

export default App;
