export const removeTokenFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete('token');
  window.history.replaceState(
    {},
    document.title,
    `${window.location.pathname}${
      urlParams.toString() ? `?${urlParams.toString()}` : ''
    }`,
  );
};

export const isOkRequest = async (response: Response) => {
  if (response.ok) {
    return response.json();
  }
  // Try to get error message from response
  return response.json().then((data: { message?: string }) => {
    throw new Error((data?.message as string) || 'Something went wrong');
  });
};
