import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  List,
  ListItem,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect, useState, useMemo, memo } from 'react';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from '../../../context/Translations';
import {
  AreaType,
  BuildingType,
  CategoryType,
  SpaceType,
} from '../../../types';
import { getCategoryIconById, getFloorsString } from '../../../utils';
import { Dropdown, SelectedIon, UnSelectedIon } from '../../Icon';
import { ParkingSpacesMenu } from './ParkingSpacesMenu';
import { useSelectedProperty, useSelectedSpace } from '../../../state';

type CategoryExtendedType = CategoryType & {
  spaces: SpaceExtendedType[] & AreaType[];
};

type SpaceExtendedType = SpaceType & {
  building: { address: BuildingType['address']; id: BuildingType['id'] };
};

const sortBySpaceName = (a: SpaceExtendedType, b: SpaceExtendedType) => {
  return a.name.localeCompare(b.name, 'fi');
};

function FilterListSubItem({
  item,
  parent,
}: {
  item: SpaceExtendedType;
  parent: CategoryExtendedType;
}) {
  const [selectedSpace] = useSelectedSpace();
  const [selectedProperty] = useSelectedProperty();

  const { translate } = useTranslation();
  const bg = useColorModeValue('customGray.10', 'customGray.600');
  const subFontColor = useColorModeValue('customGray.700', 'customGray.50');
  const pathname =
    selectedSpace?.id === item.id
      ? `/${selectedProperty?.id}`
      : `/${selectedProperty?.id}/${item.building?.id || 'area'}/${item.id}`;

  return (
    <ListItem>
      <NavLink tabIndex={0} to={{ pathname }}>
        {({ isActive }) => (
          <Stack
            color={subFontColor}
            direction="row"
            alignItems="center"
            px="5"
            py="4"
            bg={bg}
          >
            <Flex w="8">
              <Icon as={isActive ? SelectedIon : UnSelectedIon} h="7" w="7" />
            </Flex>
            <Box>
              <Text className="filter-item__name">{item.name}</Text>
              {item.building ? (
                <Text fontSize="15">
                  {item.building?.address} ({item.building?.id})
                </Text>
              ) : null}
            </Box>
            <Spacer />
            <Box alignSelf="flex-end">
              {item.floors ? (
                <Text fontSize="15" whiteSpace="nowrap">
                  {translate(item.floors.length === 1 ? 'floor' : 'floors')}:{' '}
                  {getFloorsString(item.floors)}
                </Text>
              ) : null}
            </Box>
          </Stack>
        )}
      </NavLink>
    </ListItem>
  );
}

function FilterListItem({ item }: { item: CategoryExtendedType }) {
  const [submenuOpen, setSubmenuOpen] = useState<boolean>(false);
  const [, setChildSelected] = useState<boolean>(false);
  const toggleSubmenu = () => setSubmenuOpen((current) => !current);
  const { spaceSlug } = useParams<'spaceSlug'>();
  const dropdownColor = useColorModeValue('black', 'white');
  const bg = useColorModeValue('white', 'customGray.800');

  const legendIcon = getCategoryIconById(item.id);

  useEffect(() => {
    setChildSelected(() => !!item.spaces.find((i) => i.id === spaceSlug));
  }, [spaceSlug]);

  return (
    <ListItem data-id={item.id} w="100%" display="flex" flexFlow="column">
      <HStack
        borderBottom="1px"
        p="6"
        borderColor="customGray.40"
        h="16"
        className="listItem"
      >
        <Button
          borderRadius="0px"
          bg={bg}
          color="customGray.700"
          h="100%"
          variant="link"
          w="100%"
          tabIndex={0}
          onKeyDown={toggleSubmenu}
          onClick={toggleSubmenu}
        >
          <HStack w="100%">
            <Icon as={legendIcon} h="7" w="7" mr="1.5" />
            <Text w="85%" textAlign="left">
              {item.name}
            </Text>
            {!item.spaces.length ? null : (
              <HStack justifyContent="center" w="10%">
                <Icon
                  as={Dropdown}
                  h="4"
                  w="3.5"
                  color={dropdownColor}
                  transform={` ${submenuOpen ? 'rotate(-180deg);' : ''}`}
                />
              </HStack>
            )}
          </HStack>
        </Button>
      </HStack>

      {item.spaces.length ? (
        <List
          w="100%"
          flexFlow="column"
          display={submenuOpen ? 'flex' : 'none'}
          bg="customGray.10"
        >
          {item.spaces.sort(sortBySpaceName).map((space: SpaceExtendedType) => (
            <FilterListSubItem key={space.id} item={space} parent={item} />
          ))}
        </List>
      ) : null}
    </ListItem>
  );
}

function SpacesFilters() {
  const [selectedProperty] = useSelectedProperty();
  const [searchParams] = useSearchParams();
  const bg = useColorModeValue('white', 'customGray.800');

  // Group spaces by category
  const groupedSpaces = useMemo(() => {
    const gSpaces: any = {};
    selectedProperty?.buildings?.forEach((building) => {
      building.spaces.forEach((space) => {
        if (space.category) {
          // 12.10.2023: Hide 'Käytävä- ja porrastilat' from navigation
          if (space.category.id !== 'TRKP') {
            if (!gSpaces[space.category.name]) {
              gSpaces[space.category.name] = {
                ...space.category,
                spaces: [],
              };
            }

            gSpaces[space.category.name].spaces.push({
              ...space,
              building: { address: building.address, id: building.id },
            });
          }
        }
      });
    });

    return gSpaces;
  }, [selectedProperty]);

  const hasParking = useMemo(
    () =>
      !!selectedProperty?.areas?.some((area) => area?.category?.id === 'ALPK'),
    [selectedProperty],
  );

  if (!selectedProperty) {
    return null;
  }

  const sortCategories = (a: string, b: string) => {
    return a.localeCompare(b, 'fi');
  };

  return (
    <Box
      height="100%"
      bg={bg}
      maxH="calc(var(--vh, 1vh)*100 - 13.8em)"
      overflowY="auto"
    >
      <List
        flexFlow="column"
        bg={bg}
        className={`filters__list ${
          searchParams.get('showParking') !== null
            ? 'filters__list--show-parking-active'
            : ''
        }`}
      >
        {Object.keys(groupedSpaces)
          .sort(sortCategories)
          .map((key) => (
            <FilterListItem key={key} item={groupedSpaces[key]} />
          ))}
        {hasParking ? <ParkingSpacesMenu /> : ''}
      </List>
    </Box>
  );
}

export default memo(SpacesFilters);
