import { Box, Button, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import React, { useEffect } from 'react';
import {
  useMainMenuOpen,
  useProperties,
  useSelectedProperty,
} from '../../state';

import SpacesFilters from './Filters';
import Search from './Search';

import { useTranslation } from '../../context/Translations';

import { Hamburger, MenuCloseIcon, ReturnIcon } from '../Icon';
import { MenuHeader } from './MenuHeader';
import { PropertiesNavigation } from './PropertiesNavigation/PropertiesNavigation';

function PropertyHeader() {
  const [selectedProperty] = useSelectedProperty();
  if (!selectedProperty) {
    return null;
  }

  return (
    <Box p="6" bg="green.500" color="white">
      <Text as="b" fontSize="20">
        {selectedProperty.name}
      </Text>
      <Text mt="0">
        {selectedProperty.region},{selectedProperty.city}
      </Text>
    </Box>
  );
}

function BackButton() {
  const { translate } = useTranslation();
  const [properties] = useProperties();

  if (!properties || properties.length === 1) {
    return null;
  }

  return (
    <Button
      as={Link}
      bg="customGray.700"
      h="20"
      w="full"
      color="white"
      borderRadius="none"
      justifyContent="left"
      to="/"
    >
      <Icon h="6" w="6" mr="5" as={ReturnIcon} />
      {translate('back-to-properties')}
    </Button>
  );
}

function SpacesNavigation() {
  const [selectedProperty] = useSelectedProperty();
  if (!selectedProperty) {
    return null;
  }

  return (
    <Box>
      <BackButton />
      <PropertyHeader />
      <SpacesFilters />
    </Box>
  );
}

/*
function BuildingsNavigation() {
  const [selectedProperty] = useSelectedProperty();
  const { translate } = useTranslation();

  if (!selectedProperty) {
    return null;
  }

  const sortByAddress = (a: BuildingType, b: BuildingType) => {
    if (a.address < b.address) return -1;
    if (a.address > b.address) return 1;
    return 0;
  };

  return (
    <Box>
      <Box>
        <Link to="/">&laquo; {translate('back-to-properties')}</Link>
      </Box>
      <ListItem>
        {selectedProperty?.buildings
          ?.sort(sortByAddress)
          .map((building) => (
            <List key={building.id}>
              <NavLink to={`/${selectedProperty?.id}/${building.id}`}>
                {building.address}
              </NavLink>
            </List>
          ))}
      </ListItem>
    </Box>
  );
}
*/

function Navigation() {
  const [selectedProperty] = useSelectedProperty();

  if (selectedProperty) {
    return (
      <Box mt="-96px" position="relative">
        <SpacesNavigation />
      </Box>
    );
  }

  return (
    <Box maxH="calc(var(--vh, 1vh)*100 - 11em)" overflowY="auto">
      <PropertiesNavigation />
    </Box>
  );
}

function MenuToggleButton({
  isOpen,
  onClick,
}: {
  isOpen: boolean;
  onClick: () => void;
}) {
  const iconColor = useColorModeValue('customGray.900', 'white');
  const bg = useColorModeValue('#F6F7F7', 'customGray.800');
  return (
    <Button
      w="4.5rem"
      h="16"
      p="1.4rem"
      borderWidth="0px"
      borderRight="1px"
      borderBottom="1px"
      borderColor="customGray.30"
      borderRadius="none"
      bg={bg}
      onClick={onClick}
      _hover={{ bg: bg }}
    >
      <Icon
        as={isOpen ? MenuCloseIcon : Hamburger}
        color={iconColor}
        h="6"
        w="6"
      />
    </Button>
  );
}

function Menu() {
  const [isMainMenuOpen, setMainMenuOpen] = useMainMenuOpen();

  const bg = useColorModeValue('white', 'customGray.800');

  return (
    <>
      <Box position="absolute" zIndex="1001" className="menu-toggle">
        <MenuToggleButton
          isOpen={isMainMenuOpen}
          onClick={() => setMainMenuOpen((prev) => !prev)}
        />
      </Box>
      <Box
        zIndex="1000"
        overflow="hidden"
        position="absolute"
        left="0"
        top="0"
        h="calc(var(--vh, 1vh) * 100)"
        maxW="25rem"
        w="100%"
        transition="all 0.2s ease"
        bg={bg}
        transform={isMainMenuOpen ? 'translate(0)' : 'translate(-100%)'}
        id="menu-panel"
        boxShadow="xl"
      >
        <Box>
          <MenuHeader />
          <Search />
          <Navigation />
        </Box>
      </Box>
    </>
  );
}

export default Menu;
