import { atom, useAtom } from 'jotai';
import { useAuth } from '../context/Auth';
import { fetchParkingSpaces } from '../lib/api';
import type { ParkingSpace } from '../types';

export const parkingSpacesAtom = atom<ParkingSpace[]>([]);
export const useParkingSpaces = () => useAtom(parkingSpacesAtom);

export const selectedParkingSpaceAtom = atom<ParkingSpace | null>(null);
export const useSelectedParkingSpace = () => useAtom(selectedParkingSpaceAtom);

export const useFetchParkingSpaces = () => {
  const [, setParkingSpaces] = useParkingSpaces();
  const auth = useAuth();

  const getParkingSpaces = async (id: number) => {
    const data = await fetchParkingSpaces(
      id,
      auth.authState.userToken as string,
    );
    setParkingSpaces(data);
  };

  return getParkingSpaces;
};
