const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:9091';

export const doApiRequest = async (
  path: string,
  method: 'GET' | 'POST',
  bearerToken?: string | null,
) => {
  const url = `${API_URL}${path}`;

  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  if (bearerToken) {
    headers.Authorization = `Bearer ${bearerToken}`;
  }

  const response = await fetch(url, {
    method,
    headers,
  });

  return response.json();
};

export const fetchProperties = async (
  token: string,
  searchParams: URLSearchParams,
) => {
  try {
    const data = await doApiRequest(
      `/properties?${searchParams.toString()}`,
      'GET',
      token,
    );

    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const fetchProperty = async (id: number, token: string) => {
  try {
    const data = await doApiRequest(`/properties/${id}`, 'GET', token);

    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const fetchParkingSpaces = async (id: number, token: string) => {
  const data = await doApiRequest(`/properties/${id}/parking`, 'GET', token);

  return data;
};

export const fetchEntrances = async (id: number, token: string) => {
  const data = await doApiRequest(`/properties/${id}/entrances`, 'GET', token);

  return data;
};
