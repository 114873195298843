import { atom, useAtom } from 'jotai';
import type { PropertyType } from '../types';
import { fetchProperties, fetchProperty } from '../lib/api';
import { useAuth } from '../context/Auth';
import { useFetchEntrances } from './entrances';
import { useUserCapabilities } from '../hooks/useUserCapabilities';

export const propertiesAtom = atom<PropertyType[]>([]);
export const useProperties = () => useAtom(propertiesAtom);

export const preSelectedPropertyAtom = atom<PropertyType | null>(null);
export const usePreSelectedProperty = () => useAtom(preSelectedPropertyAtom);

export const selectedPropertyAtom = atom<PropertyType | null>(null);
export const useSelectedProperty = () => useAtom(selectedPropertyAtom);

export const useFetchProperties = () => {
  const [, setProperties] = useProperties();
  const auth = useAuth();
  const { getUserCapabilities } = useUserCapabilities();

  const getProperties = async () => {
    const capabilities = getUserCapabilities();
    const propertyIds = capabilities?.properties;
    const searchParams = new URLSearchParams();
    if (propertyIds) {
      searchParams.append('id', propertyIds.join(','));
    }

    const data = await fetchProperties(
      auth.authState.userToken as string,
      searchParams,
    );
    setProperties(data);
  };

  return getProperties;
};

export const useFetchProperty = () => {
  const [, setSelectecProperty] = useSelectedProperty();
  const fetchEntrances = useFetchEntrances();
  const auth = useAuth();
  const { hasAccess } = useUserCapabilities();

  const getProperty = async (id: number) => {
    if (!hasAccess(id, 'properties')) {
      return;
    }

    const data = await fetchProperty(id, auth.authState.userToken as string);
    setSelectecProperty(data);
    if (data) {
      await fetchEntrances(id);
    }
  };

  return getProperty;
};
