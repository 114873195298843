/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import Map, {
  MapProvider,
  useMap,
  NavigationControl,
  ScaleControl,
  MapRef,
} from 'react-map-gl';
import { useParams, useSearchParams } from 'react-router-dom';

import MapboxGl from 'mapbox-gl';

import './Map.scss';
import MapStyleSelect from '../MapStyleSelect';
import PropertiesLayer from './Layers/PropertiesLayer';
import Loader from '../Loaders';
import SingleProperty from './Layers/SingleProperty';
import { ImagesLoader } from './ImagesLoader';
import {
  useMainMenuOpen,
  useProperties,
  useSelectedProperty,
  useMap as useMapAtom,
  useSelectedBuilding,
  useParkingSpaces,
  useSelectedSpace,
  useFetchProperty,
  useFetchParkingSpaces,
} from '../../state';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
MapboxGl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MapDefaultState: any = {
  minZoom: 10,
  maxZoom: 50,
  hash: true,
  attributionControl: false,
  center: [24.9305, 60.2418],
  maxBounds: [
    [24.4644, 60.0171],
    [25.3979, 60.4465],
  ],
  mapStyle:
    localStorage.getItem('layerStyle') || 'mapbox://styles/mapbox/light-v9',
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '',
};

function MapRouteHandler({
  setLoader,
}: {
  setLoader: (state: boolean) => void;
}) {
  const { propertySlug, buildingSlug, spaceSlug } = useParams<
    'propertySlug' | 'buildingSlug' | 'spaceSlug'
  >();
  const [searchParams] = useSearchParams();
  const { mainMap } = useMap();
  const [selectedProperty, setSelectedProperty] = useSelectedProperty();
  const [selectedBuilding, setSelectedBuilding] = useSelectedBuilding();
  const [parkingSpaces, setParkingSpaces] = useParkingSpaces();
  const [, setSelectSpace] = useSelectedSpace();
  const fetchProperty = useFetchProperty();
  const fetchParkingSpaces = useFetchParkingSpaces();

  const getProperty = async (slug: string) => {
    setLoader(true);
    try {
      await fetchProperty(+slug);
    } finally {
      setLoader(false);
    }
  };

  const getParkingSpaces = async (slug: string) => {
    setLoader(true);
    try {
      await fetchParkingSpaces(+slug);
    } finally {
      setLoader(false);
    }
  };

  // Listen propertySlug param changes
  useEffect(() => {
    if (propertySlug) {
      if (+propertySlug !== selectedProperty?.id) {
        getProperty(propertySlug);
      }
    } else if (selectedProperty?.id && !propertySlug && mainMap) {
      setTimeout(() => {
        mainMap.easeTo({
          center: MapDefaultState.center,
          zoom: 11,
        });
      }, 250);
      mainMap.once('moveend', () => setSelectedProperty(null));
    }
  }, [propertySlug]);

  // Listen buildingSlug param changes
  useEffect(() => {
    if (selectedProperty && buildingSlug) {
      const found = selectedProperty?.buildings?.find(
        (b) => b.id === +buildingSlug,
      );
      setSelectedBuilding(found ?? null);
    } else if (selectedProperty && !buildingSlug) {
      setSelectedBuilding(null);
    }
  }, [buildingSlug, selectedProperty]);

  // Listen spaceSlug param changes
  useEffect(() => {
    if (selectedBuilding && spaceSlug) {
      const found = selectedBuilding?.spaces?.find((s) => s.id === spaceSlug);
      setSelectSpace(found ?? null);
    } else if (selectedBuilding && !spaceSlug) {
      setSelectSpace(null);
    } else if (buildingSlug === 'area' && spaceSlug) {
      const found = selectedProperty?.areas?.find(
        (a) => a.id === spaceSlug,
      ) as any;

      setSelectSpace(found ?? null);
    } else if (!buildingSlug && !spaceSlug) {
      setSelectSpace(null);
    }
  }, [spaceSlug, selectedBuilding]);

  // Listen showParking searchparam changes
  useEffect(() => {
    if (propertySlug) {
      if (searchParams.get('showParking') !== null) {
        getParkingSpaces(propertySlug);
      } else if (
        searchParams.get('showParking') === null &&
        parkingSpaces.length
      ) {
        setParkingSpaces([]);
      }
    }
  }, [searchParams.get('showParking'), propertySlug]);

  return null;
}

function MainMap() {
  const [properties] = useProperties();
  const [mainMenuOpen] = useMainMenuOpen();
  const [selectedProperty] = useSelectedProperty();
  const [, setMap] = useMapAtom();
  const [cursor, setCursor] = useState<string>('auto');
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [showPropertyLoader, setShowProperyLoader] = useState<boolean>(false);

  const mapRef = React.useRef<MapRef>(null);

  const [viewState] = React.useState(MapDefaultState);

  const onMouseEnter = React.useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = React.useCallback(() => {
    setCursor('auto');
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      setMap(mapRef.current);
    }
  }, [mapRef.current]);

  useEffect(() => {
    if (mainMenuOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
    setTimeout(() => {
      mapRef.current?.resize();
    }, 250);
  }, [mainMenuOpen]);

  if (!properties || !properties.length) {
    return <Loader />;
  }

  return (
    <MapProvider>
      <Map
        ref={mapRef}
        id="mainMap"
        {...viewState}
        initialViewState={{
          bounds: MapDefaultState.maxBounds,
          latitude: MapDefaultState.center[1],
          longitude: MapDefaultState.center[0],
        }}
        cursor={cursor}
        style={{
          // width: state.mainMenuOpen ? '80vw' : '100vw',
          // height: '100vh',
          cursor: 'pointer',
        }}
        className={mainMenuOpen ? 'menu-open' : ''}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onLoad={() => setMapLoaded(true)}
        antialias={false}
        reuseMaps
      >
        <ImagesLoader />
        <MapRouteHandler setLoader={setShowProperyLoader} />
        <MapStyleSelect />
        <ScaleControl maxWidth={80} />
        <NavigationControl position="bottom-right" showCompass visualizePitch />

        {showPropertyLoader && <Loader />}

        {mapLoaded ? (
          <>
            {selectedProperty ? (
              <SingleProperty property={selectedProperty} />
            ) : null}

            <PropertiesLayer />
          </>
        ) : (
          <Loader />
        )}
      </Map>
    </MapProvider>
  );
}

export default MainMap;
