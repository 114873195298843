import { LineLayerSpecification } from 'mapbox-gl';
import React, { useEffect } from 'react';
import { Layer, Source, useMap } from 'react-map-gl';
import { PropertyType } from '../../../types';
import { getPolygonBounds } from '../../../utils';
import Loader from '../../Loaders';
import Areas from './Areas';
import Buildings from './Buildings';
import Entrances from './Entrances';
import ParkingSpaces from './ParkingSpaces';

const SOURCE_ID = 'property-data';

const layerStyleProperty: LineLayerSpecification = {
  id: 'property',
  source: SOURCE_ID,
  type: 'line',
  layout: {
    'line-sort-key': 0,
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#5865F6',
    'line-width': 3,
    'line-dasharray': [1, 4],
    'line-opacity': 1,
  },
};

function SingleProperty({ property }: { property: PropertyType }) {
  const { mainMap } = useMap();

  const geojsonProperty: any = React.useMemo(
    () => ({
      type: 'FeatureCollection',
      features: [{ type: 'Feature', geometry: property.geo }],
    }),
    [property],
  );

  // Get property slug from url and zoom to bounds
  useEffect(() => {
    const bounds = getPolygonBounds(property.geo);
    if (bounds) {
      mainMap?.fitBounds(bounds, { essential: true });
    }
  }, [mainMap, property]);

  if (!property) {
    return null;
  }

  if (!geojsonProperty.features) {
    return <Loader />;
  }

  return (
    <>
      <Source id={SOURCE_ID} type="geojson" data={geojsonProperty}>
        <Layer {...layerStyleProperty} />
      </Source>
      <Buildings />
      <Areas />
      <ParkingSpaces />
      <Entrances />
    </>
  );
}

export default SingleProperty;
