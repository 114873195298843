import { atom, useAtom } from 'jotai';
import { useAuth } from '../context/Auth';
import { fetchEntrances } from '../lib/api';
import type { Entrance } from '../types';

export const entrancesAtom = atom<Entrance[]>([]);
export const useEntrances = () => useAtom(entrancesAtom);

export const useFetchEntrances = () => {
  const [, setEntrances] = useEntrances();
  const auth = useAuth();

  const getProperty = async (id: number) => {
    const data = await fetchEntrances(id, auth.authState.userToken as string);
    setEntrances(data);
  };

  return getProperty;
};
