import { FillLayerSpecification, LineLayerSpecification } from 'mapbox-gl';
import React from 'react';
import { Source, Layer, useMap } from 'react-map-gl';
import { useParams } from 'react-router';
import { useSelectedProperty, useSelectedBuilding } from '../../../state';
import { getPolygonBounds } from '../../../utils';
import Spaces from './Spaces';

const SOURCE_ID = 'buildings-data';

const layerStyleBuildings: FillLayerSpecification = {
  id: 'buildings',
  source: SOURCE_ID,
  type: 'fill',
  paint: {
    'fill-color': '#a1a7ef',
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      1,
      1,
    ],
  },
};

const layerStyleBuildingsStroke: LineLayerSpecification = {
  id: 'buildings-stroke',
  source: SOURCE_ID,
  type: 'line',
  layout: {
    'line-sort-key': 0,
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#000000',
    'line-width': 1,
    'line-opacity': 0.5,
    // 'line-dasharray': [3, 6],
  },
};

function Buildings() {
  const { mainMap } = useMap();
  const [selectedProperty] = useSelectedProperty();
  const [selectedBuilding] = useSelectedBuilding();

  const { buildingSlug } = useParams<
    'propertySlug' | 'buildingSlug' | 'spaceSlug'
  >();

  const geojsonBuildings: any = React.useMemo(
    () => ({
      type: 'FeatureCollection',
      features: selectedProperty?.buildings?.map((building) => ({
        type: 'Feature',
        geometry: building.geo,
        properties: { ...building },
      })),
    }),
    [selectedProperty],
  );

  // Get property slug from url and zoom to bounds
  React.useEffect(() => {
    let bounds: any = null;
    if (selectedBuilding) {
      bounds = getPolygonBounds(selectedBuilding.geo);
    } else if (
      buildingSlug === 'area' &&
      selectedProperty &&
      !selectedBuilding
    ) {
      bounds = getPolygonBounds(selectedProperty.geo);
    } else if (!selectedBuilding && selectedProperty) {
      bounds = getPolygonBounds(selectedProperty.geo);
    }

    if (bounds) {
      setTimeout(() => {
        mainMap?.fitBounds(bounds, {
          padding: 100,
          maxZoom: 20,
          essential: true,
        });
      }, 100);
    }
  }, [mainMap, selectedBuilding, buildingSlug]);

  return (
    <>
      <Source id={SOURCE_ID} type="geojson" data={geojsonBuildings}>
        <Layer {...layerStyleBuildings} />
        <Layer {...layerStyleBuildingsStroke} />
      </Source>
      <Spaces />
    </>
  );
}

export default Buildings;
