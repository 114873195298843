import {
  useColorModeValue,
  List,
  ListItem,
  HStack,
  Button,
  Icon,
  Box,
  Text,
} from '@chakra-ui/react';
import React, { useCallback, useMemo, memo } from 'react';
import { useMap, usePreSelectedProperty, useProperties } from '../../../state';
import { PropertyType } from '../../../types';
import { PinIcon } from '../../Icon';

import styles from './PropertiesNavigation.module.css';

const sortByName = (a: PropertyType, b: PropertyType) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

type ItemProps = {
  id: string;
  propertyId: number;
  propertyName: string;
  onClick: () => void;
};

const NavigationItem = memo(
  ({ id, propertyId, propertyName, onClick }: ItemProps) => {
    const [preSelectedProperty] = usePreSelectedProperty();

    const isSelected = useMemo(
      () => preSelectedProperty?.id === propertyId,
      [preSelectedProperty?.id],
    );

    return (
      <ListItem
        onClick={onClick}
        id={id}
        w="100%"
        className={[
          styles.navItem,
          isSelected ? styles.naviItemActive : '',
        ].join(' ')}
        borderBottom="1px"
        borderColor="customGray.40"
        cursor="pointer"
        h="16"
        p="5"
        display="flex"
        alignItems="center"
        gap="2"
      >
        <Icon as={PinIcon} className={styles.navItemIcon} h="7" w="7" />
        <Text maxW="99%" noOfLines={2}>
          {propertyName}
        </Text>
        <Text flexShrink={0} fontSize="xs" ml="auto" fontWeight="normal">
          KPID {propertyId}
        </Text>
      </ListItem>
    );
  },
);

NavigationItem.displayName = 'NavigationItem';

export const PropertiesNavigation = memo(
  ({ _isInActive }: { _isInActive?: boolean }) => {
    const [map] = useMap();
    const listRef = React.useRef<HTMLUListElement>(null);
    const [preSelectedProperty, preSelectProperty] = usePreSelectedProperty();
    const [properties] = useProperties();
    const [isClick, setIsClick] = React.useState(false);

    const onClick = useCallback(
      (property: PropertyType) => {
        if (map) {
          map.getMap().fire('click', {
            propertyTargetId: property.id,
            originalEvent: {} as MouseEvent,
          } as any);
          setIsClick(true);
          preSelectProperty(property);
        }
      },
      [map],
    );

    React.useEffect(() => {
      if (preSelectedProperty?.id && !isClick) {
        const listItemEl = listRef.current?.querySelector(
          `#navi-property-${preSelectedProperty.id}`,
        );
        listItemEl?.scrollIntoView({
          block: 'center',
          inline: 'start',
        });
      }

      setIsClick(false);
    }, [preSelectedProperty?.id]);

    const list = useMemo(
      () => properties.sort(sortByName),
      [properties.length],
    );

    return (
      <Box className={styles.navigationWrapper}>
        <List ref={listRef}>
          {list.map((property) => (
            <NavigationItem
              id={`navi-property-${property.id}`}
              key={`navi-property-${property.id}`}
              propertyId={property.id}
              propertyName={property.name}
              onClick={() => onClick(property)}
            />
          ))}
        </List>
      </Box>
    );
  },
);

PropertiesNavigation.displayName = 'PropertiesNavigation';
