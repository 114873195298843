import { useCallback } from 'react';
import { Capabilities, User } from '../types';
import { useAuth } from '../context/Auth';

export const useUserCapabilities = () => {
  const auth = useAuth();

  const getUserCapabilities = useCallback(() => {
    return auth.user?.capabilities || ({} as User['capabilities']);
  }, [auth.user]);

  const hasAccess = useCallback(
    (id: number, capability: keyof Capabilities) => {
      const capabilities =
        auth.user?.capabilities || ({} as User['capabilities']);
      const entities = capabilities[capability];
      if (!entities || entities?.length === 0) {
        return true;
      }

      return entities.includes(id) ?? false;
    },
    [auth.user?.capabilities],
  );

  return { getUserCapabilities, hasAccess };
};
