import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Layer, Popup, Source, useMap } from 'react-map-gl';

import centroid from '@turf/centroid';
import type {
  FillLayerSpecification,
  LineLayerSpecification,
  SymbolLayerSpecification,
} from 'mapbox-gl';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from '../../../context/Translations';
import { ParkingSpace } from '../../../types';
import {
  useParkingSpaces,
  useSelectedParkingSpace,
  useSelectedProperty,
} from '../../../state';

type HoverInfoProps = {
  features: Array<any>;
  x: number;
  y: number;
};

const LAYER_ID = 'parking-spaces';
const LAYER_ID_HOVER = 'parking-spaces-hover';
const SOURCE_ID = 'parking-spaces-data';

const STATUS_COLORS: any = {
  vapautumassa: '#64DCC3',
  varattu: '#C3C9CB',
  vapaa: '#078571',
};

const layerStyleSpaces: FillLayerSpecification = {
  id: LAYER_ID,
  type: 'fill',
  source: SOURCE_ID,
  // if geometry type is polygon or multipolygon
  filter: ['==', ['geometry-type'], 'Polygon'],
  paint: {
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'selected'], false],
      ['get', 'color'],
      ['get', 'color'],
    ],
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'selected'], false],
      1,
      0.75,
    ],
  },
};
const layerStyleSpacesLabel: SymbolLayerSpecification = {
  id: `${LAYER_ID}-label`,
  source: SOURCE_ID,
  type: 'symbol',
  minzoom: 19,
  // if geo type is point
  filter: ['==', ['geometry-type'], 'Point'],
  layout: {
    'text-field': ['get', 'title'],
    'text-size': 12,
  },
  paint: {
    'text-color': '#000',
    'text-opacity': 1,
  },
};

const layerStyleSpacesStroke: LineLayerSpecification = {
  id: `${LAYER_ID}-stroke`,
  source: SOURCE_ID,
  type: 'line',
  filter: ['==', ['geometry-type'], 'Polygon'],
  layout: {
    'line-sort-key': 0,
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#000000',
    'line-width': 1.5,
    'line-opacity': 0.5,
    // 'line-dasharray': [3, 6],
  },
};

const layerHoverStyle: LineLayerSpecification = {
  id: LAYER_ID_HOVER,
  source: SOURCE_ID,
  type: 'line',
  filter: ['==', ['geometry-type'], 'Polygon'],
  paint: {
    'line-width': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      2,
      0,
    ],
  },
};

function ToolTip({ tooltipInfo }: any) {
  return (
    <Box
      position="absolute"
      m="2"
      p="2"
      background="black"
      color="white"
      maxWidth="72"
      fontSize="20"
      zIndex="9"
      pointerEvents="none"
      left={tooltipInfo.x}
      top={tooltipInfo.y}
    >
      {tooltipInfo.features.map((feature: any) => (
        <Box key={feature.properties.id}>
          <Text>
            {feature.properties.name} ({feature.properties.id})
          </Text>
        </Box>
      ))}
    </Box>
  );
}

function SelectedParkingSpacePopup() {
  const [selectedParkingSpace] = useSelectedParkingSpace();

  const navigate = useNavigate();
  const { translate } = useTranslation();
  const bg = useColorModeValue('white', 'black');
  const color = useColorModeValue('black', 'white');

  if (!selectedParkingSpace) {
    return null;
  }

  const feature = centroid(selectedParkingSpace.geo, {
    properties: {
      ...selectedParkingSpace,
    },
  });

  return (
    <Popup
      maxWidth="28"
      className="selected-parking-space-popup"
      closeOnClick
      closeButton
      offset={20}
      onClose={() => {
        const params = new URLSearchParams(window.location.search);
        params.delete('selectedParkingSpace');
        navigate({
          pathname: window.location.pathname,
          search: params.toString(),
        });
      }}
      latitude={feature.geometry.coordinates[1]}
      longitude={feature.geometry.coordinates[0]}
    >
      <Box bg={bg} color={color} p="4" boxShadow="xl">
        <Box mb="4">
          <Text fontSize="20" as="b">
            {feature.properties?.name}({feature.properties.id})
          </Text>
        </Box>
        <Box mb="4">
          <Text>
            {translate('area-id')} {feature.properties.area.id}
          </Text>
        </Box>
        <Box
          className="popup-footer"
          borderRadius="3px"
          style={{
            backgroundColor:
              STATUS_COLORS[feature.properties.status.toLowerCase()],
            color:
              feature.properties.status.toLowerCase() === 'vapautumassa'
                ? '#222'
                : '#fff',
          }}
        >
          {translate('status')}: {translate(feature.properties.status)}
        </Box>
      </Box>
    </Popup>
  );
}

let hoveredId: any = null;
let selectedId: any = null;
function Spaces() {
  const { mainMap } = useMap();
  const [selectedParkingSpace, selectParkingSpace] = useSelectedParkingSpace();
  const [parkingSpaces] = useParkingSpaces();
  const [searchParams] = useSearchParams();
  const [selectedProperty] = useSelectedProperty();

  const navigate = useNavigate();
  const location = useLocation();
  const [hoverInfo, setHoverInfo] = React.useState<HoverInfoProps | null>(null);
  const geojsonParkingSpaces: any = React.useMemo(() => {
    const features: any = [];
    parkingSpaces.forEach((space: ParkingSpace) => {
      const center = centroid(space.geo, {
        properties: {
          ...space,
        },
      });

      const polygon = {
        type: 'Feature',
        id: +space.fid,
        geometry: space.geo,
        properties: {
          color: STATUS_COLORS[space.status.toLowerCase()],
          ...space,
          categoryId: space.category.id,
          categoryName: space.category.name,
        },
      };

      features.push(polygon);
      features.push(center);
    });
    return {
      type: 'FeatureCollection',
      features,
    };
  }, [selectedProperty?.id, parkingSpaces]);

  const setHoverFeatureState = (id: number | null) => {
    // un-hilight current
    if (hoveredId !== null) {
      mainMap?.setFeatureState(
        { source: SOURCE_ID, id: hoveredId },
        { hover: false },
      );

      hoveredId = null;
    }

    // if feature id provided. set feature state
    if (id) {
      hoveredId = id;
      mainMap?.setFeatureState(
        { source: SOURCE_ID, id: hoveredId },
        { hover: true },
      );
    }
  };

  const onHover = React.useCallback((event: any) => {
    const {
      features,
      point: { x, y },
    } = event;
    if (mainMap) {
      mainMap.getCanvas().style.cursor = 'pointer';
    }
    const hoveredFeature = features && features[0];
    setHoverFeatureState(hoveredFeature.id);
    setHoverInfo(hoveredFeature ? { features: [features[0]], x, y } : null);
  }, []);

  const onMouseLeave = () => {
    setHoverFeatureState(null);
    setHoverInfo(null);
    if (mainMap) {
      mainMap.getCanvas().style.cursor = '';
    }
  };

  const onClick = (
    e: mapboxgl.MapMouseEvent & {
      features?: any;
    },
  ) => {
    const feature = e.features && e.features[0];
    if (feature) {
      const params = new URLSearchParams(window.location.search);
      params.set('selectedParkingSpace', feature.properties.id);
      navigate({
        pathname: window.location.pathname,
        search: params.toString(),
      });
    }
  };

  const setSelectedFeatureState = (id: number | null) => {
    // un-hilight current
    if (selectedId !== null) {
      mainMap?.setFeatureState(
        { source: SOURCE_ID, id: selectedId },
        { selected: false },
      );

      selectedId = null;
    }

    // if feature id provided. set feature state
    if (id) {
      selectedId = id;
      mainMap?.setFeatureState(
        { source: SOURCE_ID, id: selectedId },
        { selected: true },
      );
    }
  };

  useEffect(() => {
    if (searchParams.get('selectedParkingSpace')) {
      const found = parkingSpaces.find(
        (p) => p.id === searchParams.get('selectedParkingSpace'),
      );
      selectParkingSpace(found || null);
    } else {
      selectParkingSpace(null);
    }
  }, [searchParams.get('selectedParkingSpace')]);

  useEffect(() => {
    setSelectedFeatureState(selectedParkingSpace?.fid || null);
  }, [selectedParkingSpace]);

  useEffect(() => {
    mainMap?.on('mousemove', LAYER_ID, onHover);
    mainMap?.on('mouseleave', LAYER_ID, onMouseLeave);
    mainMap?.on('click', LAYER_ID, onClick);

    return () => {
      mainMap?.off('mousemove', LAYER_ID, onHover);
      mainMap?.off('mouseleave', LAYER_ID, onMouseLeave);
      mainMap?.off('click', LAYER_ID, onClick);
    };
  }, [location.pathname]);

  return (
    <>
      <Source id={SOURCE_ID} type="geojson" data={geojsonParkingSpaces}>
        <Layer {...layerStyleSpaces} />
        <Layer {...layerHoverStyle} />
        <Layer {...layerStyleSpacesStroke} />
        <Layer {...layerStyleSpacesLabel} />
      </Source>
      {hoverInfo && <ToolTip tooltipInfo={hoverInfo} />}
      <SelectedParkingSpacePopup />
    </>
  );
}

export default Spaces;
